import React, { useMemo, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Badge, Button, makeStyles, Subtitle2, Tab, TabList } from "@fluentui/react-components";
import { List, ListItem } from "@fluentui/react-list-preview";
import { Link, useNavigate } from "react-router-dom";
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { AdvancedMarker, InfoWindow, Map, useMap } from "@vis.gl/react-google-maps";
import { ThumbLikeRegular, EyeTrackingFilled } from '@fluentui/react-icons';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: '1fr',
    gap: '0px 3px',
    justifyItems: 'start',
    marginTop: '10px',
  },
  navigation: {
    marginTop: '10px',
    width: '100%',
  },
  content: {
    width: '80%',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  }
});

export const IssuesHome = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const map = useMap("home");

  const [openIssues, setOpenIssues] = useState([]);
  const [closedIssues, setClosedIssues] = useState([]);
  const [currentTab, setCurrentTab] = useState('open');
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [markers, setMarkers] = useState({});

  const fetchOpenIssues = useCallback(async () => {
    const resp = await fetch("/api/issues?status=open,inprogress");
    const body = await resp.json();
    const { issues } = body;

    setOpenIssues(issues);
  }, [setOpenIssues]);

  const fetchClosedIssues = useCallback(async () => {
    const resp = await fetch("/api/issues?status=completed");
    const body = await resp.json();
    const { issues } = body;

    setClosedIssues(issues);
  }, [setClosedIssues]);

  useEffect(() => {
    fetchOpenIssues();
  }, [fetchOpenIssues]);

  useEffect(() => {
    fetchClosedIssues();
  }, [fetchClosedIssues]);

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    if (markers && Object.keys(markers).length > 0) {
      clusterer.addMarkers(Object.values(markers));
      fitMapToBounds();
    }
  }, [clusterer, markers, currentTab, map]);

  const fitMapToBounds = () => {
    let issues = openIssues;
    if (currentTab === 'closed') {
      issues = closedIssues;
    }
    
    if (issues.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      issues.filter(issue => issue.coordinates && issue.coordinates.latitude && issue.coordinates.longitude)
        .map(issue => ({
          lat: issue.coordinates.latitude,
          lng: issue.coordinates.longitude
        }))
        .forEach((position) => bounds.extend(position));

      map.fitBounds(bounds);
      if (issues.length === 1) {
        map.setZoom(10);
      }
    }
  }

  const setMarkerRef = useCallback((marker, issue) => {
    setMarkers(markers => {
      if ((marker && markers[issue.id]) || (!marker && !markers[issue.id]))
        return markers;
      if (marker) {
        return { ...markers, [issue.id]: marker };
      } else {
        const { [issue.id]: _, ...newMarkers } = markers;
        return newMarkers;
      }
    });
  }, []);

  const trimTitle = (title) => {
    if (!title) return title;
    if (title.length < 30) return title;
    return title.substring(0, 30) + " ...";
  }

  const onMapClick = useCallback((ev) => {
    setSelectedIssue(null);
  }, []);

  const onTabClick = (tab) => {
    setCurrentTab(tab);
    setSelectedIssue(null);
  }

  const handleMarkerClick = useCallback((issue) => {
    setSelectedIssue(issue);
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedIssue(null);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.navigation}>
        {/* <div style={{ display: 'flex', flexDirection: 'row', columnGap: 5 }}>
          <Field style={{ width: 50 }} size='small' label='Keyword' />
          <Input style={{ minWidth: '75%' }} size='small' autoComplete='off' />
        </div> */}
        <TabList selectedValue={currentTab} onTabSelect={(ev, data) => onTabClick(data.value)}>
          <Tab value="open">Open Issues</Tab>
          <Tab value="closed">Closed Issues</Tab>
        </TabList>
        {currentTab === 'open' &&
          <div style={{ marginTop: 10 }}>
            <List style={{ paddingLeft: 10 }}>
              {openIssues.map((issue, idx) => (
                <ListItem key={issue.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Link to={`/${issue.id}`} onMouseLeave={() => setSelectedIssue(null)} onMouseOver={() => setSelectedIssue(issue)}>{trimTitle(issue.title)}</Link>
                  </div>
                  <div>
                    <Badge size='small' icon={<ThumbLikeRegular />} color='brand'>
                      <small style={{ marginTop: 2 }}>{issue.votes}</small>
                    </Badge>
                    <EyeTrackingFilled style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => selectedIssue ? setSelectedIssue(null) : setSelectedIssue(issue)} />
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        }
        {currentTab === 'closed' &&
          <div style={{ marginTop: 10 }}>
            <List style={{ paddingLeft: 10 }}>
              {closedIssues.map((issue) => (
                <ListItem key={issue.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Link to={`/${issue.id}`} onMouseLeave={() => setSelectedIssue(null)} onMouseOver={() => setSelectedIssue(issue)}>{issue.title}</Link>
                  </div>
                  <div>
                    <Badge size='small' icon={<ThumbLikeRegular />} color='brand'>
                      <small style={{ marginTop: 2 }}>{issue.votes}</small>
                    </Badge>
                    <EyeTrackingFilled style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => setSelectedIssue(issue)} />
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        }
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Button appearance='primary' size='small' onClick={() => navigate('/new-issue')}>Report Issue in your Area</Button>
        </div>
        <div style={{ height: '85vh', width: '100%' }}>
          <Map
            mapId='bf51a910020fa25a'
            defaultZoom={2}
            id="home"
            reuseMaps={true}
            onClick={onMapClick}
            defaultCenter={{ lat: 0, lng: 0 }}
            disableDefaultUI={true}
            zoomControl={true}
            mapTypeControl={true}
          >
            {currentTab === 'open' &&
              openIssues.map(issue => (
                <AdvancedMarkerWithRef
                  position={{ lat: issue.coordinates.latitude, lng: issue.coordinates.longitude }}
                  key={issue.id}
                  issue={issue}
                  onClick={handleMarkerClick}
                  setMarkerRef={setMarkerRef}
                />
              ))
            }
            {currentTab === 'closed' &&
              closedIssues.map(issue => (
                <AdvancedMarkerWithRef
                  position={{ lat: issue.coordinates.lat, latitude: issue.coordinates.longitude }}
                  key={issue.id}
                  onClick={handleMarkerClick}
                  setMarkerRef={setMarkerRef}
                  issue={issue}
                />
              ))}
            {(selectedIssue) && (
              <InfoWindow
                anchor={markers[selectedIssue.id]}
                onCloseClick={handleInfoWindowClose}
              >
                <Subtitle2>{selectedIssue.title}</Subtitle2>
                <p>{selectedIssue.description}</p>
              </InfoWindow>
            )}
          </Map>
        </div>
      </div>
    </div>
  );
};

export const AdvancedMarkerWithRef = (props) => {
  const map = useMap("home");
  const { children, issue, onClick, setMarkerRef, position, ...advancedMarkerProps } = props;

  const handleClick = useCallback(() => onClick(issue), [onClick, issue]);
  const ref = useCallback(
    (marker) =>
      setMarkerRef(marker, issue),
    [setMarkerRef, issue.id]
  );

  if (!position || !position.lat || !position.lng) {
    return <div />
  }

  return (
    <>
      <AdvancedMarker
        onClick={handleClick}
        ref={ref}
        position={position}
        {...advancedMarkerProps}
      >
        {children}
      </AdvancedMarker>
    </>
  );
};