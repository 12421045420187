import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { ThumbLikeRegular, ThumbLikeFilled } from '@fluentui/react-icons';
import { Body1Strong, Button, Field, InfoLabel, Input, makeStyles, Subtitle2, Textarea } from "@fluentui/react-components";
import SingleComment from "./SingleComment";
import NewComment from "./NewComment";

const useStyles = makeStyles({
  yes_btn: {
    "&:hover": {
      backgroundColor: 'black',
      color: 'white',
    }
  },
  yes_btn_voted: {
    backgroundColor: 'black',
    color: 'white',
    "&:hover": {
      backgroundColor: 'black',
      color: 'white',
    }
  }
});

export const Issue = ({ notify }) => {
  const styles = useStyles();
  const params = useParams();

  const [issue, setIssue] = useState(null);
  const [hover, setHover] = useState(false);
  const [votingDisabled, setVotingDisabled] = useState(false);
  const [votingResolvedDisabled, setVotingResolvedDisabled] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetch(`/api/issues/${params.id}`, {
      method: "GET",
    })
      .then(resp => resp.json())
      .then(resp => {
        const { issue } = resp;
        setIssue(issue);
      });

    fetchComments();

    let storageIssues = JSON.parse(localStorage.getItem('issues') || '{}');
    if (storageIssues && storageIssues[params.id]) {
      if (storageIssues[params.id].voted) setVotingDisabled(true);
      if (storageIssues[params.id].votedResolved) setVotingResolvedDisabled(true);
    }
  }, []);

  const vote = () => {
    if (!votingDisabled) {
      onThumbsUp();
    }
  }

  const onResolvedVote = () => {
    if (votingResolvedDisabled) return;
    setVotingResolvedDisabled(true);
    fetch(`/api/issues/${issue.id}/resolved`, { method: "PUT" })
      .then(resp => resp.json())
      .then(res => {
        setIssue(res);
        let savedIssues = JSON.parse(localStorage.getItem('issues') || '{}');
        if (savedIssues[issue.id]) {
          savedIssues[issue.id].votedResolved = true;
        } else {
          savedIssues[issue.id] = {
            voted: false,
            votedResolved: true,
          };
        }
        localStorage.setItem('issues', JSON.stringify(savedIssues));
      })
      .catch(err => {
        notify(err, 'error');
      })
  }

  const onThumbsUp = () => {
    if (votingDisabled) return;
    setVotingDisabled(true);
    fetch(`/api/issues/${issue.id}/thumbsup`, { method: "PUT" })
      .then(resp => resp.json())
      .then(res => {
        setIssue(res);
        let savedIssues = JSON.parse(localStorage.getItem('issues') || '{}');
        if (savedIssues[issue.id]) {
          savedIssues[issue.id].voted = true;
        } else {
          savedIssues[issue.id] = {
            voted: true,
            votedResolved: false,
          };
        }
        localStorage.setItem('issues', JSON.stringify(savedIssues));
      })
      .catch(err => {
        notify(err, 'error');
      })
  }

  const fetchComments = () => {
    fetch(`/api/issues/${params.id}/comments`, { method: "GET" })
      .then(resp => resp.json())
      .then(res => {
        if (res && res.comments) {
          setComments(res.comments);
        }
      })
      .catch(err => {
        notify(err, 'error');
      });
  }

  const onSubmitComment = async (comment) => {
    try {
      const resp = await fetch(`/api/issues/${issue.id}/comments`, { method: "POST", body: JSON.stringify(comment) });
      const res = await resp.json();
      if (res && res.comment) {
        setComments([...comments, res.comment]);
      } else {
        notify('cannot submit comment', 'error');
      }

      return res && res.comment;
    } catch (err) {
      notify(err, 'error');
    }
    return false;
  }

  const likeComment = (commentId) => {
    fetch(`/api/issues/${params.id}/comments/${commentId}/like`, { method: "PUT" })
      .then(resp => resp.json())
      .then(res => {
        if (res && res.comment) {
          const idx = comments.findIndex(x => x.id === commentId);
          if (idx >= 0) {
            let newComments = [...comments];
            newComments[idx] = res.comment;
            setComments(newComments);
          }
        }
      })
      .catch(err => {
        notify(err, 'error');
      })
  }

  if (!issue) return <div />;

  return (
    <div style={{ width: '80%', height: 800, marginTop: 20, paddingBottom: 300 }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Subtitle2>{issue.title}</Subtitle2>
        <div>
          {(hover || votingDisabled) &&
            <ThumbLikeFilled onClick={vote} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ cursor: 'pointer' }} fontSize={32} />
            ||
            <ThumbLikeRegular onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ cursor: 'pointer' }} fontSize={32} />
          }
          {issue.votes}
        </div>
      </div>
      <div style={{ height: '50vh', width: '100%' }}>
        <Map
          defaultZoom={10}
          mapId='bf51a910020fa25a'
          id="home"
          reuseMaps={true}
          defaultCenter={{ lat: issue.coordinates.latitude || 7.8731, lng: issue.coordinates.longitude || 80.7718 }}
          disableDefaultUI={true}
        >
          <AdvancedMarker
            position={{ lat: issue.coordinates.latitude, lng: issue.coordinates.longitude }}
          />
        </Map>
      </div>
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'row', columnGap: 10 }}>
          <Body1Strong>Is this issue resolved?</Body1Strong>
          <Button onClick={() => onResolvedVote()} appearance='secondary' size='small' className={votingResolvedDisabled ? styles.yes_btn_voted : styles.yes_btn}>Yes</Button>
          <div>
            <small>{issue.issue_resolved_votes} Votes</small>
            <InfoLabel info={'Automatically marked as completed when the vote count reaches 30'} />
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
          <Field style={{ width: 200 }} size='small' label="Report Date">
            <Input value={new Date(issue.create_date).toLocaleString()} />
          </Field>
          {!issue.anonymous &&
            <>
              <Field size='small' label="Name">
                <Input autoComplete='none' value={issue.reporter_name} />
              </Field>
              <Field size='small' label="Email">
                <Input autoComplete='none' value={issue.reporter_email} />
              </Field>
              <Field size='small' label="Phone">
                <Input autoComplete='none' value={issue.reporter_phone} />
              </Field>
            </>
          }
        </div>
      </div>
      <Field size='small' label="Description">
        <Textarea rows={5} value={issue.description} />
      </Field>
      <div style={{ marginTop: 25 }}>
        <Body1Strong>Comments</Body1Strong>
        <div style={{ marginTop: 10, maxWidth: '100%', height: 400, overflowY: 'auto' }}>
          {comments.map(comment => (
            <SingleComment likeComment={likeComment} key={comment.id} comment={comment} />
          ))}
          <NewComment onSubmit={onSubmitComment} />
        </div>
      </div>
    </div>
  );
};
